<template>
  <div>
    <EditorFrame v-if="hb" :hb="hb">
      <div v-if="/Verse/.test(hb.contentType)">
        <VerseEditor
          :hb="hb"
          :showImgUploader="true"
          :contentLocked="true"
          @change="contentChange"
        />
      </div>
      <div v-else-if="/Intensive/.test(hb.contentType)">
        <IntensiveEditor :hb="hb" :showImgUploader="true" :contentLocked="true" />
      </div>
      <div v-else>
        <ViewEditor4picture :hb="hb" @change="contentChange" />
      </div>

      <template v-slot:buttons>
        <template v-if="$route.query.rewriteId">
          <Button class="center-block-btn" type="primary" @click="postSubmit('supplyImgs')">提交</Button>
          <Button class="center-block-btn" type="error" @click="drop">放弃</Button>
        </template>
        <template v-else>
          <Button
            class="center-block-btn"
            type="primary"
            @click="postSubmit(0)"
            :disabled="isDisable"
          >确认无误，提交</Button>
          <DeleteHbButton :hb="hb" class="center-block-btn" :disabled="isDisable" />
        </template>
      </template>

      <template v-slot:right-side>
        <Tabs :value="'name1'">
          <TabPane label="编辑状态" name="name1">
            <EditStatus ref="editStatus" :hb="hb" />
          </TabPane>
          <TabPane label="版本记录" name="name2" v-if="taskInfo&&hb.status!='0'">
            <colophon :taskInfo="taskInfo"></colophon>
          </TabPane>
        </Tabs>
      </template>
    </EditorFrame>
  </div>
</template>


<script >
import lingYunApi from "../api/api";
import UploadImgService from "./components-for-editor/uploadBatch4Img";
import homeService from "@/api/homePageService";
import { getHbTaskView, updatePublished } from "../api/hbService";
import { getRewriteContent, rewrite_drop,rewrite_submit,rewrite_save } from "../api/startTaskService";
import editorMixin from "./editorMixin";
export default {
  data() {
    return {
      hb: null,
      taskInfo: {},
      isDisable: false // 开关
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.currentIndex = 0;
    this.$route.query.rewriteId;
    this.initTaskView();
  },
  methods: {
    contentChange() {
      this.$eventBus.$emit("hbChange");
      this.$refs.editStatus && this.$refs.editStatus.refresh();
    },
    drop() {
      rewrite_drop({
        parentId:this.hb.parentId||this.hb.bizId||this.hb.id,
        parentProcessId:this.hb.processId||this.hb.parentProcessId,
        contentType:this.hb.contentType,
        taskName:4
      })
        .then(() => {
          this.$router.back();
        })
        .catch(err => {
          this.$Message.warning(err.message || err);
        });
    },

    //获取任务详情
    initTaskView() {
      let {
        taskId,
        processId,
        bizId,
        contentType,
        rewriteId,
        parentProcessId
      } = this.$route.query;
      let params = { taskId, processId, bizId, contentType };
      let initFn = getHbTaskView;
      if (rewriteId) {
        initFn = getRewriteContent;
        params = {
          processId: parentProcessId,
          id: rewriteId,
          contentType,
          taskName: this.$store.getters.getRoleCode
        };
      }
      this.$Spin.show();
      initFn(params, () => {
        this.$Spin.hide();
      })
        .then(res => {
          this.hb = res.content || res;
          if (res.content) {
            this.hb.taskName = res.taskName;
          }
          this.taskInfo = res.taskInfo;
        })
        .catch(err => {
          this.$Message.warning(err.message || err);
        })
        .finally(() => {
          this.$Spin.hide();
        });
    },

    async postSubmit(e) {
      let msg = this.$Message.loading({
        content: "提交中....",
        duration: 0
      });
      this.isDisable = true;
      await UploadImgService(this.hb).catch(err => {
        msg();
        this.isDisable = false;
        this.$Message.warning(err);
        throw "图片错误";
      });

      // 图片上传，路径替换后，必须强制刷新页面，否则编辑器显示的仍然是老的内容！
      this.$forceUpdate();
      let hb = this.hb;
      let submitFn;
      if (e === "supplyImgs") {
        hb.pass = 0;
        submitFn = rewrite_submit;
      } else {
        hb.pass = e;
        hb.audioContentList = null;
        submitFn = lingYunApi.postSubmit;
      }
      submitFn(hb)
        .then(res => {
          msg();
          this.isDisable = false;
          this.$Message.success("提交成功");
          this.isDropTask = false;
          setTimeout(() => {
            this.$router.back();
          }, 1000);
        })
        .catch(err => {
          msg();
          this.isDisable = false;
          this.$Message.warning(err);
        });
    },
    supplyImgs() {}
  },
  mixins: [editorMixin]
};
</script>

<style scoped>
::v-deep .ivu-tabs {
  width: 600px;
}
</style>
